<template>
  <div id="changeNotice">
    <el-dialog
      :title="changeNoticeFormTitle"
      width="680px"
      :visible.sync="changeNoticeDialogVisible"
      :close-on-click-modal="false"
      @close="changeNoticeDialogClose"
    >
      <el-form
        ref="changeNoticeFormRef"
        :model="changeNoticeForm"
        :rules="changeNoticeFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="changeNoticeForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="changeNoticeForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更改实施日期" prop="changeDate">
              <el-date-picker v-model="changeNoticeForm.changeDate" placeholder="请选择更改实施日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="更改原因" prop="changeReason">
              <el-input
                v-model="changeNoticeForm.changeReason"
                placeholder="请输入更改原因"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更改内容" prop="changeContent">
              <el-input
                v-model="changeNoticeForm.changeContent"
                placeholder="请输入更改内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分发部门" prop="distributionDept">
              <el-input
                v-model="changeNoticeForm.distributionDept"
                placeholder="请输入分发部门"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="changeNoticeForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制人" prop="editor">
              <el-input v-model="changeNoticeForm.editor" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制日期" prop="editTime">
              <el-date-picker v-model="changeNoticeForm.editTime" placeholder="请选择编制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="changeNoticeForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期" prop="reviewTime">
              <el-date-picker v-model="changeNoticeForm.reviewTime" placeholder="请选择审核日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="changeNoticeForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approveTime">
              <el-date-picker v-model="changeNoticeForm.approveTime" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="changeNoticeDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="changeNoticeFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="changeNoticePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column label="更改实施日期">
        <template slot-scope="scope">
          <span v-if="scope.row.changeDate">{{ scope.row.changeDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="changeReason" label="更改原因" />
      <el-table-column prop="changeContent" label="更改内容" />
      <el-table-column prop="distributionDept" label="分发部门" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editTime">{{ scope.row.editTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="审核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewTime">{{ scope.row.reviewTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="批准日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approveTime">{{ scope.row.approveTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="changeNoticePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addChangeNotice, deleteChangeNotice, updateChangeNotice, selectChangeNoticeInfo, selectChangeNoticeList } from '@/api/technology/changeNotice'

export default {
  data () {
    return {
      changeNoticeDialogVisible: false,
      changeNoticeFormTitle: '',
      changeNoticeForm: {
        id: '',
        productName: '',
        spec: '',
        changeDate: '',
        changeReason: '',
        changeContent: '',
        distributionDept: '',
        remarks: '',
        editor: '',
        editTime: '',
        reviewer: '',
        reviewTime: '',
        approver: '',
        approveTime: ''
      },
      changeNoticeFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      changeNoticePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectChangeNoticeList(this.searchForm).then(res => {
      this.changeNoticePage = res
    })
  },
  methods: {
    changeNoticeDialogClose () {
      this.$refs.changeNoticeFormRef.resetFields()
    },
    changeNoticeFormSubmit () {
      if (this.changeNoticeFormTitle === '设计更改通知单详情') {
        this.changeNoticeDialogVisible = false
        return
      }
      this.$refs.changeNoticeFormRef.validate(async valid => {
        if (valid) {
          if (this.changeNoticeFormTitle === '新增设计更改通知单') {
            await addChangeNotice(this.changeNoticeForm)
          } else if (this.changeNoticeFormTitle === '修改设计更改通知单') {
            await updateChangeNotice(this.changeNoticeForm)
          }
          this.changeNoticePage = await selectChangeNoticeList(this.searchForm)
          this.changeNoticeDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.changeNoticeFormTitle = '新增设计更改通知单'
      this.changeNoticeDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteChangeNotice(row.id)
        if (this.changeNoticePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.changeNoticePage = await selectChangeNoticeList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.changeNoticeFormTitle = '修改设计更改通知单'
      this.changeNoticeDialogVisible = true
      this.selectChangeNoticeInfoById(row.id)
    },
    handleInfo (index, row) {
      this.changeNoticeFormTitle = '设计更改通知单详情'
      this.changeNoticeDialogVisible = true
      this.selectChangeNoticeInfoById(row.id)
    },
    selectChangeNoticeInfoById (id) {
      selectChangeNoticeInfo(id).then(res => {
        this.changeNoticeForm.id = res.id
        this.changeNoticeForm.productName = res.productName
        this.changeNoticeForm.spec = res.spec
        this.changeNoticeForm.changeDate = res.changeDate
        this.changeNoticeForm.changeReason = res.changeReason
        this.changeNoticeForm.changeContent = res.changeContent
        this.changeNoticeForm.distributionDept = res.distributionDept
        this.changeNoticeForm.remarks = res.remarks
        this.changeNoticeForm.editor = res.editor
        this.changeNoticeForm.editTime = res.editTime
        this.changeNoticeForm.reviewer = res.reviewer
        this.changeNoticeForm.reviewTime = res.reviewTime
        this.changeNoticeForm.approver = res.approver
        this.changeNoticeForm.approveTime = res.approveTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectChangeNoticeList(this.searchForm).then(res => {
        this.changeNoticePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectChangeNoticeList(this.searchForm).then(res => {
        this.changeNoticePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectChangeNoticeList(this.searchForm).then(res => {
        this.changeNoticePage = res
      })
    }
  }
}
</script>

<style>
</style>
